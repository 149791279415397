<template>
  <div>
    <crud-table
      v-if="brandList.length"
      ref="crudTable"
      :list-items="listItems"
      :brand-list="brandList"
      :show-brands-filter="true"
      :show-search-filter="true"
      :show-rides-to-invoice-filter="true"
      :is-editable="false"
      :csv-data="csvData"
      csv-title="Passengers"
      endpoint="passengers"
      heading="customers"
      row-click-path="/tables/passengers"
      @create="createPassenger()"
      @getData="getData"
      @delete="id => deletePassenger(id)"
      @restore="id => restorePassenger(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              v-if="editDialog.type != 'edit'"
              xs12>
              <users-input
                v-model="editDialog.data.user"
                @changeUser="(inputUser) => { editDialog.data.user = inputUser.id; editDialog.userData = inputUser }" />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <v-switch
                v-model="editDialog.data.business"
                label="Business"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.companyName"
                label="Company name"
              />
            </v-flex>
            <v-flex
              xs6>
              <div>
                <span>Invoice address</span>
                <v-radio-group
                  v-model="editDialog.data.invoiceType"
                  :class="'custom-radio-inline'"
                  row
                  hide-details
                >
                  <v-radio
                    label="Private"
                    value="private"
                  />
                  <v-radio
                    label="Business"
                    value="business"
                  />
                </v-radio-group>
              </div>
            </v-flex>
            <v-flex
              xs6
            >
              <v-switch
                v-model="editDialog.data.useInvoiceEmailAsDefault"
                label="Only use invoice email"
              />
            </v-flex>
            <v-flex
              xs6>
              <v-select
                :items="invoicingTypeOptions"
                v-model="editDialog.data.invoicingType"
                item-value="value"
                item-text="text"
                label="Invoice period"
                filled
                hide-details
              />
            </v-flex>
            <v-flex
              xs6>
              <v-text-field
                v-model="editDialog.data.invoiceEmail"
                label="Invoice email"
              />
            </v-flex>
            <v-flex
              xs12>
              <address-input
                :address="editDialog.data.address"
                label="Address"
                @onChange="(val) => { editDialog.data.address = val}"/>
            </v-flex>
            <v-flex
              xs12>
              <v-textarea
                v-model="editDialog.data.description"
                label="Description"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-textarea
                v-model="editDialog.data.adminNote"
                label="Admin note"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-text-field
                v-model="editDialog.data.secondEmail"
                label="Second email"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.poNumber"
                label="PO number"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.travelKmTariff"
                  label="Travel km tariff"
                  type="number"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              v-if="roleList && editDialog.userData && editDialog.userData.id"
              xs12
              sm6>
              <v-select
                :items="Object.keys(roleList)"
                :rules="[v => v.length>0 || 'Please select at least 1 user role']"
                v-model="editDialog.userData.roles"
                multiple
                label="Roles"
              />
            </v-flex>
            <v-flex
              v-if="editDialog.userData && editDialog.userData.id"
              xs12
              sm4>
              <v-switch
                v-model="editDialog.userData.active"
                label="Active"
              />
            </v-flex>
            <v-flex
              v-if="editDialog.userData && editDialog.userData.id"
              xs12>
              <v-text-field
                v-model="editDialog.userData.plainPassword"
                :append-icon="showPasswordField ? 'visibility' : 'visibility_off'"
                :type="showPasswordField ? 'text' : 'password'"
                label="Plain password"
                @click:append="showPasswordField = !showPasswordField"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-autocomplete
                v-model="editDialog.data.defaultDriverType"
                :items="getDriverTypesEdrivers()"
                :item-text="(val) => { return val.title + ' id: ' + val.id }"
                label="Default driver type (ED)"
                item-value="id"
                hide-details
                clearable
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <group-switch
                :value="editDialog.data.insure"
                :default-value="insuranceOptionValues.ask"
                :option-values="insuranceOptionValues"
                label="Insure"
                @onChange="onInsuranceChange" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                v-if="brandList.length"
                :items="brandList"
                :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                v-model="editDialog.data.brands"
                :item-text="(val) => { return val.name }"
                clearable
                item-value="id"
                multiple
                label="Brands"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="carPositionList"
                v-model="editDialog.data.options.position"
                label="Position in car"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="driverDressList"
                v-model="editDialog.data.options.driverDress"
                label="Driver dress"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.carryLuggage"
                label="Carry luggage"
                @onChange="onCarryLuggageChange" />

            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.informalLanguage"
                label="Informal language"
                @onChange="onInformalLanguageChange" />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.keepDoorsOpen"
                label="Keep doors open"
                @onChange="onKeepDoorsOpenChange" />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.playMusic"
                label="Play music"
                @onChange="onPlayMusicChange" />
            </v-flex>

            <v-flex
              xs6
            >
              <v-switch
                v-model="editDialog.data.payOnInvoice"
                label="Pay on invoice"
              />
            </v-flex>
            <v-flex
              v-if="editDialog.type === 'edit'"
              xs12>
              <v-btn
                color="primary"
                @click="sendWelcome()">Send welcome e-mail</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import UsersInput from '../../../modules/components/UsersInput.vue'
import AddressInput from '../../../modules/components/AddressInput.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import Roles from '../../../helpers/roles'

import { BRAND, PASSENGER_CAR_POSITIONS, DRIVER_DRESSES, INSURE_PASSENGER_ASK, INSURE_PASSENGER_ALWAYS, INSURE_PASSENGER_NEVER } from '../../../constants/common'
import GroupSwitch from 'Components/GroupSwitch'

export default {
  components: { CrudTable, GroupSwitch, UsersInput, AddressInput },
  mixins: [Request, Brands, Roles],
  data () {
    return {
      brandList: [],
      driverTypes: [],
      csvData: [],
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'User id', value: 'user', textValue: 'id' },
        { text: 'Brands', value: 'brands', objectKey: 'name' },
        { text: 'Name', custom: (item) =>
        `<div>${item.name} ${item.companyName ? '(' + item.companyName + ')' : ''}</div>`
        },
        { text: 'Description', value: 'description' },
        { text: 'Address', value: 'address' },
        { text: 'Reference', value: 'reference' },
        { text: 'Insure', value: 'insure' },
        { text: '', value: null }
      ],
      insuranceOptionValues: {
        on: { text: 'ON', value: INSURE_PASSENGER_ALWAYS },
        middle: { text: 'ASK', value: INSURE_PASSENGER_ASK },
        off: { text: 'OFF', value: INSURE_PASSENGER_NEVER }
      },
      loading: true,
      data: [],
      carPositionList: PASSENGER_CAR_POSITIONS,
      driverDressList: DRIVER_DRESSES,
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {
          options: {},
          brands: []
        },
        userData: {
          id: null,
          active: false,
          roles: [],
          plainPassword: null
        }
      },
      invoicingTypeOptions: [
        { text: 'each ride', value: 'per_ride' },
        { text: 'weekly', value: 'weekly' },
        { text: 'monthly', value: 'monthly' }
      ],
      showPasswordField: false,
      roleList: [],
    }
  },
  async beforeMount () {
    try {
      this.brandList = await this.fetchBrands()
      this.roleList = await this.fetchRoles()
      this.driverTypes = await this.fetchDriverTypes()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    getDriverTypesEdrivers () {
      if (this.driverTypes && this.driverTypes.length) {
        return this.driverTypes.filter(item => item.brand.name === BRAND.EDRIVERS)
      }
    },
    fetchDriverTypes () {
      return new Promise((resolve, reject) => {
        this.request('GET', '/driver-types', {}, ({ data }) => {
          resolve(data.data)
        }, null, reject)
      })
    },
    onCarryLuggageChange (value) {
      this.editDialog.data.options.carryLuggage = value
    },
    onInformalLanguageChange (value) {
      this.editDialog.data.options.informalLanguage = value
    },
    onKeepDoorsOpenChange (value) {
      this.editDialog.data.options.keepDoorsOpen = value
    },
    onPlayMusicChange (value) {
      this.editDialog.data.options.playMusic = value
    },
    onInsuranceChange (value) {
      this.editDialog.data.insure = value
    },
    getData (passengers) {
      this.csvData = passengers.map(passenger => ({
        passenger: `${passenger.name || '-'}`,
        'address business': passenger.addressBusiness || '-',
        'address private': passenger.addressPrivate || '-',
        email: passenger.user.email || '-',
        phone: passenger.user.phone ? passenger.user.phone.number : '-',
        description: passenger.description || '-',
        id: passenger.id
      }))
    },
    createPassenger () {
      this.$router.push('/tables/passenger/create')
    },
    deletePassenger (id) {
      this.request('DELETE', `/passengers/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Passagier is verwijderd'
        })
      })
    },
    restorePassenger (id) {
      this.request('PATCH', `/trash/un-delete/passenger/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Customer is restored'
        })
      })
    },
    sendWelcome () {
      let body = {
        data: {
          passenger: this.editDialog.data.id
        }
      }

      this.request('POST', '/emailing/passenger-set-password', body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Welcome message has been sent!'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
